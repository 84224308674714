import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { LANGUAGES } from "@/constants/LangConstants"

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1)

export const useLang = () => {
  const { locale } = useIntl()

  const onLangChange = useCallback(
    (lang: string) => {
      const { pathname, search } = window.location
      window.location.href = (pathname + search).replace(
        `/${locale.toLowerCase().substring(0, 2)}`,
        `/${lang.toLowerCase().substring(0, 2)}`
      )
    },
    [locale]
  )

  return {
    locale,
    onLangChange,
    langOptions: LANGUAGES.map(({ code: value, label }) => ({ label, value })),
    langOptionsXs: LANGUAGES.map(({ code: value }) => ({
      label: capitalize(value.substring(0, 2)),
      value,
    })),
  }
}
