import { Image } from "@/Components/common/Image"
import "./style.css"

export const WhyUs = () => {
  return (
    <section className="home_section why">
      <div className="container">
        <h2 className="h2 why__h2">Why Us ?</h2>
        <div className="why__row why__row_fast">
          <picture>
            <source srcSet="/img/1.png" media="(max-width: 768px)" />
            <source srcSet="/img/1-x2.png" media="(max-width: 768px)" />
            <Image src="why-1.png" alt="" />
          </picture>
          <div className="why__fast">
            <h3 className="why__fast-title">Fast and Secure</h3>
            <p className="why__fast-text">
              Your data is 100% safe with us.
              <br />
              Among the existing encryption standards we use the highest ones
            </p>
          </div>
        </div>
        <div className="why__row why__row_middle">
          <div className="why__row-item">
            <picture>
              <source srcSet="/img/2.png" media="(max-width: 768px)" />
              <source srcSet="/img/2-x2.png" media="(max-width: 768px)" />
              <Image src="why-2.png" alt="" />
            </picture>
            <h3 className="why__row-item-title">TRANSPARENT FEES</h3>
            <p className="why__row-item-text">
              All fees are stated before you proceed with the transaction. No hidden fees &
              surprises
            </p>
          </div>
          <div className="why__row-item">
            <picture>
              <source srcSet="/img/3.png" media="(max-width: 768px)" />
              <source srcSet="/img/3-x2.png" media="(max-width: 768px)" />
              <Image src="why-3.png" alt="" />
            </picture>
            <h3 className="why__row-item-title">24/7 Support</h3>
            <p className="why__row-item-text">You rest while we support!</p>
          </div>
        </div>
        <div className="why__row">
          <div className="why__row-full">
            <picture>
              <source srcSet="/img/4.png" media="(max-width: 768px)" />
              <source srcSet="/img/4-x2.png" media="(max-width: 768px)" />
              <Image src="why-4.png" alt="" />
            </picture>
            <h3 className="why__row-full-title">2 types of unique cards</h3>
            <div className="why__row-full-text">
              <p>Choose the right and most convenient tool for storing your funds. </p>
              <p>Be sure your funds are secure while enjoying convenience.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
