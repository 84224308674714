interface IBackButton {
  onClick: () => void
  className?: string
  text?: string
}
export const BackButton = ({ text, className, ...props }: IBackButton) => {
  return (
    <button className={`btn-reset btn-sq btn-long btn-back ${className}`} {...props}>
      {text ?? "GO BACK"}
    </button>
  )
}
