import { Link } from "react-router-dom"
import "./style.css"

interface ITextProps extends React.HTMLAttributes<HTMLElement> {
  variant?: "paperTitle" | "subtitle"
  to?: string
}

export const Text = ({ variant = "paperTitle", to, ...props }: ITextProps) => {
  if (to != null)
    return <Link {...{ to }} className={`text_link ${props.className ?? ""}`} {...props} />
  if (variant === "subtitle")
    return <p className={`text-subtitle ${props.className ?? ""}`} {...props}></p>
  return <h1 className={`paper-title ${props.className ?? ""}`} {...props} />
}
