import { Button } from "@/Components/Control/Button"
import { Container } from "@/Layout/Container"
import { scrollTo } from "@/utils"

export const CardHero = () => {
  return (
    <div className="hero__background">
      <Container className="card__hero-container">
        <div className="header__wrap">
          <h1 className="header__title h2">
            💳 Get a Debit Crypto Card For Your Everyday Purchase
          </h1>
          <Button minWidth={219} variant="primary" onClick={() => scrollTo("sing-up")}>
            Order card
          </Button>
        </div>
      </Container>
    </div>
  )
}
